import { useMutation } from "@apollo/client";
import { CircularProgress, Grid } from "@mui/material";
import jwtDecode from "jwt-decode";
import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";

import { ApolloClient, DOL_AUTH } from "../../Api/index.js";
import { setDOLToken } from "../../util/dolTokenStorage.js";

export default function AuthenticateDOL() {
	const history = useHistory();

	useEffect(() => {
		const urlParams = new URLSearchParams(window.location.search);
		const key = urlParams.get("key");
		const receiptNo = urlParams.get("receiptNo");

		let variables = null;
		let restrictedView = false;
		if (key && (key.length === 20 || key.length === 16)) {
			variables = { key: key };
			restrictedView = key.length === 16;
		} else if (
			key &&
			key.length === 32 &&
			receiptNo &&
			receiptNo.length >= 8 &&
			receiptNo.length <= 20
		) {
			variables = { key: key, receipt: receiptNo };
		}

		if (variables !== null) {
			getDOLAuth({ variables: variables }).then(response => {
				const token = response?.data?.requestDirectOrderLinkToken;
				if (token) {
					setJWT(token);
					urlParams.delete("key");
					if (!receiptNo) {
						urlParams.delete("receiptNo");
					}
					history.push({
						pathname: restrictedView ? `/receipt` : `/orderDetails`,
						search: urlParams.toString(),
						hash: window.location.hash
					});
				} else {
					history.push("/?status=LinkAuthenticationError");
				}
			});
		} else {
			history.push("/?staus=missingUrlParams");
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const setJWT = token => {
		const { exp } = jwtDecode(token);
		if (exp > Date.now() / 1000) {
			setDOLToken(token);
		}
	};

	const handleError = ({ message }) => {
		console.error(message);
		if (message.includes("CODE_EXPIRED")) {
			history.push("/?status=LinkKeyExpired");
		} else {
			history.push("/?status=LinkAuthenticationError");
		}
	};

	const [getDOLAuth] = useMutation(DOL_AUTH, {
		client: ApolloClient,
		onError: handleError
	});

	return (
		<Grid
			container
			sx={{ width: "100vw", height: "100vh" }}
			justifyContent="center"
			alignItems="center"
		>
			<CircularProgress />
		</Grid>
	);
}
