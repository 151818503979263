import { DSPalette } from "@clickbank-ui/seller-design-system";
import CloseIcon from "@mui/icons-material/Close";
import { Dialog, DialogContent, DialogTitle, IconButton, Typography } from "@mui/material";
import PropTypes from "prop-types";
import React from "react";
import { Trans } from "react-i18next";

import assetPath from "../../config/config";

const { cbNeutral } = DSPalette;

const orderToolTip_img_1 = assetPath.concat("images/most-cards.png");
const orderToolTip_img_2 = assetPath.concat("images/amex.png");

const SecurityHelpTip = ({ handleToggle = {} }) => {
	return (
		<Dialog
			open={open}
			maxWidth="xs"
			onClose={handleToggle}
			aria-labelledby="security-code-modal"
			aria-describedby="security-code"
		>
			<DialogTitle>
				<Typography variant="h4">
					<Trans i18nKey="SecurityHelpTip.whereFindSecurityCode">
						Where can I find my Security Code?
					</Trans>
				</Typography>
				<IconButton
					onClick={handleToggle}
					size="large"
					sx={{
						position: "absolute",
						right: 8,
						top: 8,
						color: cbNeutral[700]
					}}
				>
					<CloseIcon />
				</IconButton>
			</DialogTitle>
			<DialogContent>
				<Typography variant="h6" sx={{ marginTop: "3%" }}>
					<Trans i18nKey="SecurityHelpTip.mostCards">Most Cards</Trans>
				</Typography>
				<Typography sx={{ fontSize: "16px", marginTop: "1%" }}>
					<Trans i18nKey="SecurityHelpTip.reviewCreditParagraph">
						Most cards have a 3-digit code printed on the back of the card, to the right
						of the card number.
					</Trans>
				</Typography>
				<img
					style={{
						maxHeight: "100%",
						maxWidth: "100%",
						marginTop: "2%"
					}}
					src={orderToolTip_img_1}
					alt="payment"
				/>
				<Typography variant="h6" sx={{ marginTop: "3%" }}>
					<Trans i18nKey="SecurityHelpTip.amex">American Express</Trans>
				</Typography>
				<Typography sx={{ fontSize: "16px", marginTop: "1%" }}>
					<Trans i18nKey="SecurityHelpTip.amexParagraph">
						American Express cards have a 4-digit code printed on the front, to the
						right of the card number.
					</Trans>
				</Typography>
				<img
					style={{
						maxHeight: "100%",
						maxWidth: "100%",
						marginTop: "2%",
						marginBottom: "1rem"
					}}
					src={orderToolTip_img_2}
					alt="payment with paypal"
				/>
			</DialogContent>
		</Dialog>
	);
};

SecurityHelpTip.propTypes = {
	handleToggle: PropTypes.func
};

export default SecurityHelpTip;
