import * as yup from "yup";
import { useMutation } from "@apollo/client";
import { yupResolver } from "@hookform/resolvers/yup";
import { LoadingButton } from "@mui/lab";
import { Alert, Box, Button, DialogActions, DialogContent } from "@mui/material";
import PropTypes from "prop-types";
import React, { useContext, useState } from "react";
import { useForm } from "react-hook-form";
import { Trans, getI18n } from "react-i18next";
import { useHistory } from "react-router-dom";

import { ApolloClient, EDIT_CONTACT } from "../../Api";
import { AppContext } from "../../context";
import { requiredEmail } from "../../util/validation";
import { ControlledTextField } from "../ControlledTextField";
import IntlPhoneInput from "../IntlPhoneInput";
import BuildModalPanel from "./BuildModalPanel";

const schema = yup.object().shape({
	email: requiredEmail(),
	confirmEmail: yup
		.string()
		.test(
			"equal",
			<Trans i18nKey="EditInfoModal.emailsMustMatch">Email addresses must match.</Trans>,
			function (v) {
				// Don't use arrow functions
				const ref = yup.ref("email");
				return v === this.resolve(ref);
			}
		)
});

const ContactInfo = ({ handleClose, receipt, email, phoneNumber, fullName, hasRebills }) => {
	const [phoneIsValid, setPhoneIsValid] = useState(null);

	const phoneNumberRequired = phoneNumber;

	const form = useForm({
		resolver: yupResolver(schema),
		mode: "onTouched",
		defaultValues: {
			email,
			confirmEmail: email,
			phoneNumber
		},
		context: { phoneNumberRequired }
	});
	const history = useHistory();

	const [errorOpen, setErrorOpen] = useState(false);

	const {
		setAlertText,
		setAlert,
		setActionSuccessful,
		refetchOrderByReceiptNo,
		refetchOrderHistory
	} = useContext(AppContext);

	const handleError = () => {
		setErrorOpen(true);
	};

	const callback = value => {
		setPhoneIsValid(value);
	};

	const [editContact, { loading }] = useMutation(EDIT_CONTACT, {
		client: ApolloClient,
		onCompleted: (_, options) => {
			const isOrderHistory = window.location.pathname === "/orderHistory";
			if (options.variables.email !== email && hasRebills && isOrderHistory) {
				if (isOrderHistory) {
					refetchOrderHistory();
					handleClose();
					setAlertText(
						<Trans i18nKey="EditInfoModal.contactSuccess">
							Your contact information has been successfully updated.
						</Trans>
					);
					setActionSuccessful(true);
					setAlert(true);
				} else {
					history.push("/orderHistory");
				}
			} else {
				handleClose();
				setAlertText(
					<Trans i18nKey="EditInfoModal.contactSuccess">
						Your contact information has been successfully updated.
					</Trans>
				);
				setActionSuccessful(true);
				setAlert(true);
				refetchOrderByReceiptNo(receipt);
			}
		},
		onError: handleError
	});

	const submit = form.handleSubmit(
		({ email, phoneNumber }) => {
			const locale = getI18n()?.language;

			return editContact({
				variables: {
					email,
					phoneNumber,
					receipt,
					fullName,
					locale
				}
			});
		},
		fieldErrors => console.warn("Validation failed", fieldErrors)
	);

	return (
		<Box className="ms-form">
			<BuildModalPanel
				title={
					<Trans i18nKey="EditInfoModal.contactInfoTitle">
						Edit Your Contact Information
					</Trans>
				}
				handleCloseModalFn={handleClose}
			/>
			<DialogContent>
				<ControlledTextField
					control={form.control}
					name="email"
					id="emailAddress"
					label={<Trans i18nKey="Common.emailAddress">Email Address</Trans>}
					fullWidth
					sx={{ marginTop: 0 }}
					onChange={() => {
						// re-trigger validation
						form.trigger("confirmEmail");
					}}
				/>
				<ControlledTextField
					control={form.control}
					name="confirmEmail"
					id="emailAddressConfirmation"
					label={
						<Trans i18nKey="EditInfoModal.confirmEmailAddress">
							Confirm Email Address
						</Trans>
					}
					fullWidth
					sx={{ marginTop: "4px" }}
					onChange={() => {
						// re-trigger validation
						form.trigger("confirmEmail");
					}}
					inputProps={{ maxLength: 255 }}
				/>

				{phoneNumber && (
					<IntlPhoneInput form={form} oldPhoneNumber={phoneNumber} callback={callback} />
				)}

				{errorOpen && (
					<Alert severity="error" onClose={setErrorOpen(false)} sx={{ mt: 2 }}>
						<Trans Trans i18nKey="EditInfoModal.contactUpdateError">
							An unknown error has occurred. To make updates to your contact
							information please contact customer support directly.
						</Trans>
					</Alert>
				)}
				<Alert severity="warning" sx={{ mt: 2 }}>
					<Trans Trans i18nKey="EditInfoModal.contactInfoWarning">
						Please note that changing your email address associated with this order will
						reassign it under that email address and remove it from this order history
						upon saving.
					</Trans>
				</Alert>
			</DialogContent>
			<DialogActions>
				<Button color="secondary" onClick={handleClose}>
					<Trans i18nKey="EditInfoModal.cancelButton">Cancel</Trans>
				</Button>
				<LoadingButton
					color="primary"
					disabled={!form.formState.isValid || phoneIsValid === false || loading}
					onClick={submit}
					loading={loading}
				>
					<Trans i18nKey="EditInfoModal.saveChangesButton">Save Changes</Trans>
				</LoadingButton>
			</DialogActions>
		</Box>
	);
};

ContactInfo.propTypes = {
	handleClose: PropTypes.func,
	receipt: PropTypes.string,
	email: PropTypes.string,
	phoneNumber: PropTypes.string,
	fullName: PropTypes.string,
	hasRebills: PropTypes.bool
};

export default ContactInfo;
