import { Box, Dialog } from "@mui/material";
import PropTypes from "prop-types";
import React, { useEffect, useMemo, useState } from "react";

import { isRefundAuthFailState, isResubmitRefundAllowed } from "../../../util/orderHelpers";
import CreateTicketModal from "../../CreateTicketModal";
import ReturnInstructionsModal from "../../ReturnInstructionsModal/ReturnInstructionsModal";
import SupportTicket from "../../SupportTicket";
import NonRecurringItem from "./NonRecurringItem";
import RecurringItem from "./RecurringItem";

const ProductDescription = ({
	lineItem,
	orderDetailsInfo,
	currencyCode,
	receiptNo,
	hasBeenRebilled,
	tickets
}) => {
	const [isTicketModalOpen, setTicketModalOpen] = useState(false);
	const [ticketType, setTicketType] = useState(undefined);
	const [instructionsModalOpen, setInstructionsModalOpen] = useState(false);
	const [refundExpired, setRefundExpired] = useState(false);

	const handleTicketModalClose = () => setTicketModalOpen(false);

	const handleInstructionsModalClose = () => setInstructionsModalOpen(false);

	const showSecondaryBtnSupportTicket =
		lineItem?.refunded || lineItem?.paymentPlan?.activeStatus === "CANCELED";

	const hasBeenRefunded = lineItem?.refunded;

	const isProductAccessible = () => {
		if (
			(!lineItem.paymentPlan && !hasBeenRefunded) ||
			lineItem.paymentPlan?.activeStatus === "ACTIVE"
		)
			return lineItem.product?.productType.digital;
		return false;
	};

	const isResendShippingDetail = !!lineItem.shippingNotice;

	const expDate = lineItem.refundExpirationDate;
	const refundExpDate = useMemo(() => {
		if (expDate !== null) {
			return new Date(expDate);
		}
	}, [expDate]);

	const refundEligible =
		!hasBeenRefunded && lineItem.refundable && !refundExpired && lineItem.price > 0.0;
	const isRefundRequestable = refundEligible && !lineItem.shippingNotice;

	const isSubscriptionCancelable =
		lineItem.paymentPlan?.activeStatus === "ACTIVE" && !hasBeenRefunded;

	const hasTickets = lineItem.supportTickets?.length !== 0;

	const refundEligibility = () => {
		if (lineItem?.refunded) return "REFUNDED";
		if (lineItem.price === 0.0) return "NONE";
		if (isRefundRequestable) return "REFUND_ELIGIBLE";
		if (refundEligible) return "REFUND_ELIGIBLE_SHIPPED";
		return "REFUND_NOT_ELIGIBLE";
	};

	const isRefundOrCancelState = () =>
		hasBeenRefunded ||
		!!lineItem.returnShippingNotice ||
		lineItem.paymentPlan?.activeStatus === "CANCELED" ||
		lineItem.supportTickets?.some(
			ticket => ticket.ticketType === "REFUND" || ticket.ticketType === "CANCEL"
		);

	const getTickets = () => {
		return lineItem.supportTickets?.map((value, index) => (
			<SupportTicket
				key={index}
				isSubscriptionCancelable={isSubscriptionCancelable}
				isRefundRequestable={isRefundRequestable}
				displaySecondaryButton={!showSecondaryBtnSupportTicket}
				lineItem={lineItem}
				ticket={value}
				receipt={receiptNo}
				tickets={tickets}
			/>
		));
	};

	const email = orderDetailsInfo?.billingCustomer?.email;

	useEffect(() => {
		if (refundExpDate <= new Date()) {
			setRefundExpired(true);
		}
	}, [refundExpDate]);

	return (
		<Box>
			<Dialog
				maxWidth="sm"
				open={isTicketModalOpen}
				onClose={handleTicketModalClose}
				aria-labelledby="create-ticket-modal"
				aria-describedby="create-ticket"
			>
				<CreateTicketModal
					receipt={receiptNo}
					lineItemId={lineItem.id}
					handleClose={handleTicketModalClose}
					ticketType={ticketType}
					isSubscriptionCancelable={isSubscriptionCancelable}
					isRefundRequestable={isRefundRequestable}
					product={lineItem.product}
				/>
			</Dialog>

			<Dialog
				maxWidth="sm"
				open={instructionsModalOpen}
				onClose={handleInstructionsModalClose}
				aria-labelledby="return-instructions-modal"
				aria-describedby="return-instructions"
			>
				<ReturnInstructionsModal
					handleClose={handleInstructionsModalClose}
					instructions={lineItem.product.vendor.physicalReturnInstructions}
				/>
			</Dialog>

			{!lineItem.recurring ? (
				<NonRecurringItem
					email={email}
					lineItem={lineItem}
					currencyCode={currencyCode}
					refundEligibility={refundEligibility}
					refundExpDate={refundExpDate}
					refundExpired={refundExpired}
					setTicketModalOpen={setTicketModalOpen}
					setTicketType={setTicketType}
					receiptNo={receiptNo}
					isRefundOrCancelState={isRefundOrCancelState}
					isRefundAuthFailState={isRefundAuthFailState(orderDetailsInfo, lineItem)}
					isResubmitRefundAllowed={isResubmitRefundAllowed(orderDetailsInfo, lineItem)}
					refundAuthDate={orderDetailsInfo.authDate}
					isProductAccessible={isProductAccessible}
					isRefundEligible={refundEligible}
					isRefundRequestable={isRefundRequestable}
					hasTickets={hasTickets}
					isResendShippingDetail={isResendShippingDetail}
					setInstructionsModalOpen={setInstructionsModalOpen}
				/>
			) : (
				<RecurringItem
					email={email}
					lineItem={lineItem}
					currencyCode={currencyCode}
					refundEligibility={refundEligibility}
					refundExpDate={refundExpDate}
					refundExpired={refundExpired}
					setTicketModalOpen={setTicketModalOpen}
					setTicketType={setTicketType}
					receiptNo={receiptNo}
					isRefundOrCancelState={isRefundOrCancelState}
					isRefundAuthFailState={isRefundAuthFailState(orderDetailsInfo, lineItem)}
					isResubmitRefundAllowed={isResubmitRefundAllowed(orderDetailsInfo, lineItem)}
					isRefundAuthDate={orderDetailsInfo.authDate}
					isProductAccessible={isProductAccessible}
					isRefundEligible={refundEligible}
					isRefundRequestable={isRefundRequestable}
					hasTickets={hasTickets}
					isSubscriptionCancelable={isSubscriptionCancelable}
					hasBeenRebilled={hasBeenRebilled}
					isResendShippingDetail={isResendShippingDetail}
					setInstructionsModalOpen={setInstructionsModalOpen}
				/>
			)}

			{lineItem.supportTickets && getTickets()}
		</Box>
	);
};

ProductDescription.propTypes = {
	lineItem: PropTypes.object,
	orderDetailsInfo: PropTypes.object,
	currencyCode: PropTypes.string,
	receiptNo: PropTypes.string,
	hasBeenRebilled: PropTypes.bool,
	tickets: PropTypes.arrayOf(PropTypes.object)
};

export default ProductDescription;
