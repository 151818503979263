import { DSPalette } from "@clickbank-ui/seller-design-system";
import { Close } from "@mui/icons-material";
import { Dialog, DialogContent, DialogTitle, IconButton, Typography } from "@mui/material";
import PropTypes from "prop-types";
import { Trans } from "react-i18next";

import assetPath from "../../config/config";

const { cbNeutral } = DSPalette;

const orderToolTip_img_2 = assetPath.concat("images/orderHelpToolTip_2.png");

const OrderNumberModal = ({ handleCloseModalFn, open }) => {
	const handleClose = () => {
		handleCloseModalFn();
	};

	return (
		<Dialog
			open={open}
			maxWidth="sm"
			onClose={handleClose}
			aria-labelledby="order-number-modal"
			aria-describedby="order-number"
		>
			<DialogTitle>
				<Typography sx={{ fontSize: "1.5rem", fontWeight: "400", lineHeight: 1.333 }}>
					<Trans i18nKey="OrderNumberModal.whereFindOrderNumber">
						Where can I find my Order Number?
					</Trans>
				</Typography>
				<IconButton
					aria-label="close"
					onClick={handleClose}
					sx={{
						position: "absolute",
						right: 8,
						top: 8,
						color: cbNeutral[700]
					}}
				>
					<Close />
				</IconButton>
			</DialogTitle>
			<DialogContent>
				<Typography variant="subtitle1">
					<strong>
						<Trans i18nKey="OrderNumberModal.searchYourEmail.header">
							Search Your Email for a Receipt
						</Trans>
					</strong>
				</Typography>
				<Typography>
					<Trans i18nKey="OrderNumberModal.searchYourEmail.paragraph">
						An email containing your order number would have been sent to you at the
						time of your purchase. We recommend searching your email for keywords like
						"CLKBANK" or "noreply@clickbank.com." Additionally, make sure to check your
						email's Trash and Spam folders.
					</Trans>
				</Typography>
				<Typography variant="subtitle1" sx={{ marginTop: "1.5rem" }}>
					<strong>
						<Trans i18nKey="OrderNumberModal.paypalOrders.header">PayPal Orders</Trans>
					</strong>
				</Typography>
				<Typography>
					<Trans i18nKey="OrderNumberModal.paypalOrders.paragraph">
						Your order number is listed as the "Invoice ID" in the Transaction Details
						of your PayPal account. Or, it will appear on your bill as "
						<strong>PAYPAL CLICKBANK</strong>"
					</Trans>
				</Typography>
				<img
					style={{
						maxHeight: "100%",
						maxWidth: "100%",
						margin: "1rem auto"
					}}
					src={orderToolTip_img_2}
					alt="payment with paypal"
				/>
			</DialogContent>
		</Dialog>
	);
};

OrderNumberModal.propTypes = {
	handleCloseModalFn: PropTypes.func,
	open: PropTypes.bool
};

export default OrderNumberModal;
