import { useQuery } from "@apollo/client";
import { DSPalette } from "@clickbank-ui/seller-design-system";
import { DSBreakpoints } from "@clickbank-ui/seller-design-system";
import { ChatBubble, Email, FiberManualRecord, InfoOutlined, Phone } from "@mui/icons-material";
import {
	Box,
	Button,
	Card,
	CardActions,
	CardContent,
	Grid,
	Link,
	List,
	ListItem,
	ListItemIcon,
	ListItemText,
	Tooltip,
	Typography
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { Trans } from "react-i18next";

import { SUPPORT_AVAILABILITY } from "../../Api";
import CopyButton from "../../components/CopyButton/CopyButton";

const breakpoints = DSBreakpoints.default;
const { cbNeutral, palette } = DSPalette;

const styles = {
	cardContainer: {
		paddingTop: "1.65rem",
		textAlign: "center",

		"& .MuiCard-root": {
			width: "100%",
			maxWidth: 400,
			height: "100%",
			margin: "0 auto"
		},
		"& .MuiCardActions-root": {
			display: "block",
			justifyContent: "center",
			padding: "0 1rem 1rem",

			[breakpoints.up("md")]: {
				padding: "0 2rem 2rem"
			}
		},
		"& .MuiCardContent-root": {
			margin: "0 auto",
			padding: "1rem",
			textAlign: "center",

			[breakpoints.up("md")]: {
				padding: "2rem"
			},

			"& a": {
				whiteSpace: "nowrap"
			},
			"& .MuiSvgIcon-root": {
				marginBottom: "4px",
				padding: "8px",
				border: `solid 1px ${cbNeutral[950]}`,
				borderRadius: "4px"
			},
			"& .MuiTypography-body1": {
				marginTop: ".5rem"
			},
			"& .MuiTypography-h4": {
				color: palette.primary.main,
				fontWeight: 600
			}
		},
		".fullWidth & .MuiCard-root": {
			height: "100%"
		}
	},
	closeButton: {
		position: "absolute",
		top: 10,
		right: 10
	},
	emailLinks: {
		display: "flex",
		flexDirection: "column",
		alignItems: "center",
		width: "100%",

		"& a": {
			alignSelf: "center"
		},
		"& a + svg": {
			alignSelf: "center",
			padding: "8px"
		}
	},
	shortCard: {
		[breakpoints.up("sm")]: {
			minHeight: 285
		}
	}
};

const ContactCardsPanel = () => {
	const [supportData, setSupportData] = useState(null);
	// eslint-disable-next-line no-unused-vars
	const [winOpen, setWinOpen] = useState(false);

	const popupChat = () => {
		const url = process.env.REACT_APP_CHATBOT_URL;
		setWinOpen(true);
		const win = window.open(
			url,
			"icPatronChatWin",
			"location=no,height=630,menubar=no,status=no,width=410",
			true
		);
		win.moveTo(50, 50);
		const timer = setInterval(() => {
			if (win.closed) {
				clearInterval(timer);
				setWinOpen(false);
			}
		}, 1000);
	};

	const { loading } = useQuery(SUPPORT_AVAILABILITY, {
		context: { noAuth: true },
		onCompleted: data => {
			setSupportData(data);
		}
	});

	const phoneAvailable = supportData?.supportAvailability?.phone?.currentlyAvailable ?? true;
	const chatAvailable = supportData?.supportAvailability?.chat?.currentlyAvailable ?? true;

	useEffect(() => {
		const hash = window.location.hash;
		if (hash === "#contactUs") {
			const element = document.getElementById(hash.substring(1));
			// Need to give time for the rest of the DOM to render or else it doesn't think it needs to scroll
			setTimeout(() => {
				element?.scrollIntoView({ behavior: "smooth", block: "start" });
			}, 1000);
		}
	}, []);

	return (
		<Box>
			<Typography variant="h2">
				<Trans i18nKey="Common.contactUs">Contact Us</Trans>
			</Typography>
			<Typography variant="body1" sx={{ maxWidth: 780 }}>
				<Trans i18nKey="ContactCardsPanel.intro">
					If you have questions about your purchase or need additional support, please
					contact our Customer Support team to speak with our friendly agents.
				</Trans>
			</Typography>
			<Box sx={styles.cardContainer}>
				<Grid container spacing={2}>
					<Grid item xs={12} sm={6}>
						<Card sx={styles.shortCard}>
							<CardContent>
								<ChatBubble color="primary" />
								<Typography variant="h4" as="h3">
									<Trans i18nKey="Common.liveChat">Live Chat</Trans>
								</Typography>
								<Typography variant="body1">
									<Trans i18nKey="ContactCardsPanel.chatHours">
										6 AM to 8 PM MST
									</Trans>
								</Typography>
								<Typography variant="body1">
									<Trans i18nKey="ContactCardsPanel.chatDays">
										7 Days a Week
									</Trans>
								</Typography>
								<Typography variant="subtitle2" as="p">
									<Trans i18nKey="ContactCardsPanel.contactDaysException">
										(Except major holidays)
									</Trans>
								</Typography>
							</CardContent>
							<CardActions>
								<Button
									color="primary"
									sx={{ marginTop: "12px" }}
									onClick={popupChat}
									id="contactUs"
								>
									<Trans i18nKey="Common.chatNow">Chat Now</Trans>
								</Button>
								{!chatAvailable && !loading && (
									<Typography color="error" sx={{ marginTop: "12px" }}>
										<Trans i18nKey="Common.currentlyUnavailable">
											Currently Unavailable
										</Trans>
									</Typography>
								)}
								<Typography variant="body3" mt={4}>
									<em>
										<Trans i18nKey="ContactCardsPanel.languagesSupported">
											Languages Supported:
										</Trans>
									</em>
								</Typography>
								<Typography variant="body3" mt={1}>
									<Trans i18nKey="ContactCardsPanel.enEsFrPtDe">
										English, Spanish, French, Portuguese, German
									</Trans>
								</Typography>
							</CardActions>
						</Card>
					</Grid>
					<Grid item xs={12} sm={6}>
						<Card>
							<CardContent>
								<Phone color="primary" />
								<Typography variant="h4" as="h3">
									<Trans i18nKey="Common.callUs">Call Us</Trans>
								</Typography>
								<Typography variant="body1">
									<Trans i18nKey="ContactCardsPanel.phoneHours">
										6 AM to 8 PM MST
									</Trans>
								</Typography>
								<Typography variant="body1">
									<Trans i18nKey="ContactCardsPanel.phoneDays">
										7 Days a Week
									</Trans>
								</Typography>
								<Typography variant="subtitle2" as="p">
									<Trans i18nKey="ContactCardsPanel.contactDaysException">
										(Except major holidays)
									</Trans>
								</Typography>
							</CardContent>
							<CardActions>
								<Typography variant="body1">
									<Trans i18nKey="ContactCardsPanel.tollFreeUS">
										Toll Free (US):
									</Trans>{" "}
									<Link
										href="tel:1-800-390-6035"
										sx={{ textDecoration: "underline" }}
									>
										+1 800-390-6035
									</Link>
								</Typography>
								<Typography variant="body1">
									<Trans i18nKey="Common.international">International:</Trans>{" "}
									<Link
										href="tel:+1-208-345-4245"
										sx={{ textDecoration: "underline" }}
									>
										+1 208-345-4245
									</Link>
								</Typography>
								{!phoneAvailable && !loading && (
									<Typography color="error" sx={{ marginTop: "12px" }}>
										<Trans i18nKey="Common.currentlyUnavailable">
											Currently Unavailable
										</Trans>
									</Typography>
								)}
								<Typography variant="body3" mt={4}>
									<em>
										<Trans i18nKey="ContactCardsPanel.languagesSupported">
											Languages Supported:
										</Trans>
									</em>
								</Typography>
								<Typography variant="body3" mt={1}>
									<Trans i18nKey="ContactCardsPanel.enEs">English, Spanish</Trans>
								</Typography>
							</CardActions>
						</Card>
					</Grid>
					<Grid item xs={12} sm={4} sx={{ display: "none" }}>
						<Card sx={styles.shortCard}>
							<CardContent>
								<Email color="primary" />
								<Typography variant="h4">
									<Trans i18nKey="Common.emailUs">Email Us</Trans>
								</Typography>
								<Typography
									variant="body1"
									sx={{
										maxWidth: 180,
										marginLeft: "auto",
										marginRight: "auto",
										marginBottom: 0
									}}
								>
									<Trans i18nKey="ContactCardsPanel.emailResponses">
										Responses can take up to 24 hours.
									</Trans>
								</Typography>
							</CardContent>
							<CardActions>
								<EmailLinks />
							</CardActions>
						</Card>
					</Grid>
				</Grid>
			</Box>
		</Box>
	);
};

export default ContactCardsPanel;

const EmailLinks = () => {
	return (
		<>
			<Box sx={styles.emailLinks}>
				<Box display="flex" flexDirection="row" sx={{ marginBottom: 4 }}>
					<Link href="mailto:cs@clickbank.com">cs@clickbank.com</Link>
					<Tooltip
						placement="bottom"
						enterTouchDelay={0} // for mobile
						title={
							<>
								<Typography variant="body1">
									Please include the following:
								</Typography>
								<List>
									<ListItem>
										<ListItemIcon>
											<FiberManualRecord />
										</ListItemIcon>
										<ListItemText>
											<Trans i18nKey="Common.orderNumber">Order Number</Trans>
										</ListItemText>
									</ListItem>
									<ListItem>
										<ListItemIcon>
											<FiberManualRecord />
										</ListItemIcon>
										<ListItemText>
											<Trans i18nKey="Common.fullName">Full Name</Trans>
										</ListItemText>
									</ListItem>
									<ListItem sx={{ marginBottom: 4 }}>
										<ListItemIcon>
											<FiberManualRecord />
										</ListItemIcon>
										<ListItemText>
											{/* i18next-extract-disable-next-line */}
											<Trans i18nKey="SupportTopic.whatCanWeHelpWith">
												What can we help you with?
											</Trans>
										</ListItemText>
									</ListItem>
								</List>
							</>
						}
					>
						<InfoOutlined color="primary" />
					</Tooltip>
				</Box>
				<CopyButton url="cs@clickbank.com" />
			</Box>
		</>
	);
};
